import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
import MiniLayout from "../../components/minimalLayout"


const SubmitAssignments = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>How to submit assignments:</h2>
        <p>1. <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/tree/main/coding-foundation#pushing-changes-to-your-remote-github-repository">push it to your GitHub</a>.</p>
        <p>2. find its folder in your GitHub repository and copy the link.</p>
        <p>3. head to our <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">Class Wiki</a>.</p>
        <p>4. there you will find a section I prepared for you to add your name and paste the link.</p>
        <br></br>
        <p>Thanks!</p>
      </MiniLayout>
    </Layout>
  )
}



export default SubmitAssignments
